import React, { useEffect, useRef } from 'react'; // Make sure useRef is imported
import { createButtonElement, createButtonStateHandler } from '../button'; // Adjusted import path
import { defaultListeners } from '../listeners'; // Adjusted import path
import Vapi from '@vapi-ai/web';
import defaultAssistant from '../assistant'; // Updated import statement

const FloatingOrbButton = () => {
  const orbButtonContainerRef = useRef(null);

  useEffect(() => {
    const initializeVapi = async () => {
      try {
        console.log("Initializing Vapi");
        const assistantConfig = defaultAssistant();
        const buttonElement = createButtonElement({
          // Pass any specific configurations if necessary
        });
        const buttonStateHandler = createButtonStateHandler({
          // Pass state-specific configurations if necessary
        });
        const vapi = new Vapi(process.env.REACT_APP_VAPI_API);
        if (orbButtonContainerRef.current) {
          orbButtonContainerRef.current.appendChild(buttonElement);
          defaultListeners(vapi, buttonElement, assistantConfig, buttonStateHandler);
          console.log("Vapi initialized and listeners attached");
        }
      } catch (error) {
        console.error("Error initializing Vapi:", error);
      }
    };
    initializeVapi();

    return () => {
      if (orbButtonContainerRef.current) {
        orbButtonContainerRef.current.innerHTML = '';
        console.log("Cleaned up Vapi button");
      }
    };
  }, []); // Dependency array is empty to ensure this effect runs only once

  // Apply Tailwind CSS classes directly to the div
  // Adjust the width (w-64) and height (h-64) if needed to match your design
  return (
    <div
      ref={orbButtonContainerRef}
      className="flex-col justify-center items-center mt-32 cursor-pointer transition bg-opacity-5 bg-transparent z-30 w-36 h-36 md:w-48 md:h-48 lg:w-56 lg:h-56 xl:w-64 xl:h-64"

      />
  );
};

export default FloatingOrbButton;