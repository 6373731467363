import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Vapi from "@vapi-ai/web";
import defaultAssistant from "./assistant";
import { createButtonElement, createButtonStateHandler } from "./button";
import { defaultListeners } from "./listeners";

// Deep merge utility function
const deepMerge = (target, source) => {
  Object.keys(source).forEach((key) => {
    if (source[key] && typeof source[key] === 'object') {
      if (!target[key]) target[key] = {};
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  });
  return target;
};

const runSDK = ({
  apiKey = process.env.REACT_APP_VAPI_API, // Use the environment variable for the API key
  config = {}, // Optional: Pass custom configurations if needed
}) => {
  // Invoke defaultAssistant to get the assistant configuration
  const assistantConfig = defaultAssistant();

  const defaultConfig = {
    width: "192px",
    height: "192px",
    idle: {
      color: 'rgb(230, 184, 92)', // Default idle color
    },
    loading: {
      color: 'rgb(230, 184, 92)', // Default loading color
    },
    active: {
      color: 'rgb(255, 0, 0)', // Default active color
    },
  };

  const buttonConfig = deepMerge(defaultConfig, config);

  if (apiKey && assistantConfig) {
    const vapi = new Vapi(apiKey);
    const buttonElement = createButtonElement(buttonConfig);
    document.body.appendChild(buttonElement);

    const buttonStateHandler = createButtonStateHandler(buttonConfig);
    defaultListeners(vapi, buttonElement, assistantConfig, buttonStateHandler); // Pass the actual assistant configuration object

    window.vapiSDK.vapi = vapi;
    return vapi;
  } else {
    console.error("API Key and Assistant Configurations are required.");
    return null;
  }
};

// Properly initialize the root element for React 18+
const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

// Assign the runSDK function to the window object for global access
window.vapiSDK = {
  run: runSDK,
};