import { useLayoutEffect } from 'react';
import { Button } from "./ui/button";
import { Moon, Sun } from "lucide-react";
import RevGapLogo from './logos/RevGap';
import { useBackground } from './BackgroundContext';

const Header = () => {
  const { isDark, toggleDark } = useBackground();

  useLayoutEffect(() => {
    const el = document.documentElement;
    if (isDark) {
      el.classList.add("dark");
    } else {
      el.classList.remove("dark");
    }
  }, [isDark]);

  return (
    <div className="px-4 py-2 flex items-center h-14 z-50 bg-card border-b border-border">
      <div className="h-full flex items-center">
        <RevGapLogo className="h-24 w-auto text-black dark:text-white" />
      </div>
      <div className="ml-auto flex items-center gap-1">
        <Button
          onClick={toggleDark}
          variant="ghost"
          className="ml-auto flex items-center gap-1.5"
        >
          <span>
            {isDark ? (
              <Sun className="size-4" />
            ) : (
              <Moon className="size-4" />
            )}
          </span>
          <span>{isDark ? "Light" : "Dark"} Mode</span>
        </Button>
      </div>
    </div>
  );
};

export default Header;
