export function defaultListeners(vapi, button, assistant, buttonStateHandler) {
  let isActiveCall = false;

  const toggleCall = () => {
    console.log("Button clicked, toggling call state");
    buttonStateHandler(button, "loading");
    if (isActiveCall) {
      console.log("Stopping active call");
      vapi.stop();
      isActiveCall = false;
    } else {
      console.log("Starting new call with assistantConfig:", assistant);
      vapi.start(assistant);
      isActiveCall = true;
    }
  };

  vapi.on("call-start", () => {
    console.log("Call started");
    buttonStateHandler(button, "active");
  });

  vapi.on("call-end", () => {
    console.log("Call ended");
    buttonStateHandler(button, "idle");
  });

  vapi.on("speech-start", () => {
    console.log("Speech started");
    button.classList.add("vapi-btn-is-speaking");
  });

  vapi.on("speech-end", () => {
    console.log("Speech ended");
    button.classList.remove("vapi-btn-is-speaking");
  });

  button.addEventListener("click", toggleCall);

  vapi.on("volume-level", (audioLevel) => {
    console.log("Volume level:", audioLevel);
    const volume = Math.floor(audioLevel * 10);
    for (let i = 0; i <= 10; i++) {
      button.classList.remove(`vapi-btn-volume-${i}`);
    }
    button.classList.add(`vapi-btn-volume-${volume}`);
  });
}