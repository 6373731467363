import React from 'react';

const ArrowDownIcon = () => {
  return (
    <div className="mx-auto animate-bounce" style={{
      width: 0,
      height: 0,
      borderLeft: "20px solid transparent",
      borderRight: "20px solid transparent",
      borderTop: "40px solid #e6b85c",
    }}></div>
  );
};

const Hero = () => {
  return (
    <div className="flex-col pb-2 md:pb-4 mb-2 md:mb-4 items-center justify-center w-full min-h-[40vh] overflow-hidden bg-transparent">       
      <div className="text-center w-full space-y-4 px-4 py-2 md:py-4">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl font-extrabold tracking-tight text-[#e6b85c] drop-shadow-lg transition-colors
          [text-wrap:balance] bg-clip-text break-words">
          Say Hello to Charlie
        </h1> 
        <h2 className="text-lg sm:text-xl md:text-2xl lg:text-4xl font-bold tracking-normal text-foreground/90 drop-shadow-sm transition-colors
          max-w-[45ch] mx-auto [text-wrap:balance] break-words">
          The Salty Voice Assistant
        </h2>
        <p className="text-base sm:text-lg md:text-xl font-medium text-foreground/80 transition-colors
          max-w-[35ch] mx-auto leading-relaxed break-words">
          Click below to wake her up...
        </p>
        <div className="mt-4 md:mt-6">
          <ArrowDownIcon />
        </div>
      </div>
    </div>
  );
};

export default Hero;
