// BackgroundContext.js
import React, { createContext, useContext, useState } from 'react';

const BackgroundContext = createContext({
  isDark: true,
  toggleDark: () => {},
});

export const useBackground = () => useContext(BackgroundContext);

export const BackgroundProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(true); // Always start with dark mode
  const toggleDark = () => {
    setIsDark((prev) => !prev);
  };

  return (
    <BackgroundContext.Provider value={{ isDark, toggleDark }}>
      {children}
    </BackgroundContext.Provider>
  );
};
