import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { useBackground } from './BackgroundContext';

const Background = () => {
  const mountRef = useRef(null);
  const { isDark } = useBackground();

  useEffect(() => {
    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Store the current ref value in a local variable
    const currentMountRef = mountRef.current;
    currentMountRef.appendChild(renderer.domElement);

    // Plane Geometry
    const geometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight, 1, 1);

    // Shader Material
    const vertexShader = `
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `;
    const fragmentShader = `
      uniform float time;
      uniform vec2 resolution;
      uniform bool isDark;

      void main() {
        vec2 position = gl_FragCoord.xy / resolution.xy;
        
        // Define colors for dark and light modes
        vec3 darkBackground = vec3(47.0/255.0, 64.0/255.0, 74.0/255.0);
        vec3 lightBackground = vec3(240.0/255.0, 240.0/255.0, 240.0/255.0);
        vec3 darkOrb = vec3(51.0/255.0, 51.0/255.0, 51.0/255.0);
        vec3 lightOrb = vec3(200.0/255.0, 200.0/255.0, 200.0/255.0);
        
        // Choose colors based on theme
        vec3 backgroundColor = isDark ? darkBackground : lightBackground;
        vec3 orbColor = isDark ? darkOrb : lightOrb;
        
        // Calculate radial gradient for orb effect
        float gradient = smoothstep(0.5, 0.2, distance(position, vec2(0.5)));
        
        // Mix the background and orb colors
        vec3 color = mix(orbColor, backgroundColor, gradient);
        
        // Apply dynamic effect
        float dynamic = 0.5 + 0.5 * cos(time);
        color *= dynamic;
        
        gl_FragColor = vec4(color, 1.0);
      }
    `;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0.0 },
        resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
        isDark: { value: isDark }
      },
      vertexShader,
      fragmentShader
    });

    // Mesh
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    // Camera Position
    camera.position.z = 5;

    // Animation Loop
    const animate = () => {
      requestAnimationFrame(animate);
      material.uniforms.time.value = performance.now() / 1000;
      material.uniforms.isDark.value = isDark;
      renderer.render(scene, camera);
    };

    animate();

    // Handle resizing
    const handleResize = () => {
      const { width, height } = mountRef.current.getBoundingClientRect();
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      material.uniforms.resolution.value.set(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function using the local variable
    return () => {
      window.removeEventListener('resize', handleResize);
      currentMountRef.removeChild(renderer.domElement);
      scene.clear();
      geometry.dispose();
      material.dispose();
    };
  }, [isDark]); // Add isDark to dependencies

  return <div ref={mountRef} className="background" />;
};

export default Background;
