import React from 'react';

const RevGapLogo = (props) => {
  return (
    <svg
      viewBox="0 0 800 500"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`w-auto ${props.className || ''}`}
    >
      {/* Logo Icon */}
      <g>
        <path
          fill="#2F404A"
          d="M81.7,294c0,8.8,0,17.7,0,26.9c-3.2-0.4-6.4-0.8-9.6-1.2c-24.5-4.4-49.7-24.1-54.2-57.8
          c-6.4-46.5,34.9-85,79-77.4c9.2,1.6,17.7,5.6,24.9,12c10,8.4,19.7,16.8,29.3,25.3c0.4,0.4,0.8,0.8,1.6,1.6
          c-6.4,6.8-12.8,13.6-19.3,20.5c-3.2-2.8-6-5.6-9.2-8.4c-5.2-4.8-10.8-9.6-15.6-14.4c-11.6-11.6-31.3-10.4-42.1-4
          c-16.4,10-24.9,29.3-19.7,46.5c5.2,16.8,16.4,26.5,33.3,30.5C80.5,293.6,80.9,293.6,81.7,294z"
        />
        <path
          fill="#2F404A"
          d="M182.4,290.8c5.2-2.8,10.4-6,14.8-10.8c22.9-25.7,6.8-60.2-18.9-67c-4.8-1.2-10-0.8-14.8-1.2
          c-1.6,0-3.2-0.4-4-1.6c-6.8-6.4-14-12.4-21.3-19.3c2-1.2,4.4-2.4,6.4-3.2c20.5-8.8,40.1-6,59,5.6c38.1,23.7,44.1,75.4,12.4,107.9
          c-9.2,9.2-20.1,15.2-32.9,18.1c0-1.6,0-3.2,0-4.8C182.8,306.8,182.4,298.8,182.4,290.8z"
        />
        <path
          fill="#E6B85C"
          d="M182.6,290.6c0.1,8,0.7,15.9,0.8,23.8c0,1.6,0.1,3.2,0.1,4.8c-13.6,3.8-26.2,2.1-39.2-4.5
          c0.7-0.8,1.1-1.6,1.4-2c5.4-6.5,10.9-12.5,16.6-19c0.7-0.8,1.8-1.2,2.9-1.6C171.2,291.6,176.7,291.1,182.6,290.6z"
        />
        <path
          fill="#2F404A"
          d="M164.4,273.9c-6.8-7.6-13.2-14.8-20.1-22.1c2.4-2.4,5.2-5.6,8.4-8.4c3.2-3.2,6-6.4,9.2-9.2
          c1.2-1.2,2-1.6,3.2-0.4c6,5.6,12,11.6,18.1,17.2c0.4,0.4,0.8,2.4,0.4,2.8C177.6,259.9,171.6,266.7,164.4,273.9z"
        />
        <path
          fill="#2F404A"
          d="M133.1,261.5c7.2,6.8,13.6,13.2,20.1,19.3c-6.8,7.2-13.6,14.4-20.5,21.7c-4-4-7.6-7.6-10.8-11.2
          c-2-2-3.6-4.4-6-6.4c-2-2-1.6-3.6,0-5.2c4-4,8.4-8.4,12.4-12.4C129.9,265.1,131.5,263.5,133.1,261.5z"
        />
        <path
          fill="#2F404A"
          d="M122.2,310c-8,6.4-16.8,9.2-26.9,9.6c0-8.4,0-16.8,0-25.3c2.4-0.8,4.8-2,7.2-2.8c0.4,0,1.2,0,1.6,0.4
          C110.2,298,116.2,304,122.2,310z"
        />
      </g>

      {/* REVGAP Text */}
      <g>
        <path
          fill="currentColor"
          d="M319.6,255.2l-4.9,0.6c-0.7,0.1-1.4,0.1-2.2,0.1h-22.6v27.7c0,1.7-1.6,3-3.3,2.6c-1.2-0.3-2.1-1.5-2.1-2.8
          v-67.1c0-1.5,1.2-2.7,2.7-2.7h27.7c8.9,0,16,2.8,20.3,7.1c3.4,3.4,5.5,8.3,5.5,13.4v0.2C340.7,246.4,331.8,253.5,319.6,255.2z
           M335.4,234.5v-0.2c0-9.7-7.6-15.8-20.8-15.8h-24.7v32.5h24.4C326.4,251.1,335.4,245,335.4,234.5L335.4,234.5z M338.9,286.1
          c-0.2,0.1-0.4,0.2-0.7,0.2c-1,0-1.8-0.6-2.4-1.4L323,268.2c-1.2-1.6-0.3-3.9,1.7-4.2c0,0,0,0,0.1,0c0.9-0.1,1.9,0.2,2.5,1
          l12.9,16.7C341.4,283.1,341.4,284.6,338.9,286.1z"
        />
        <path
          fill="currentColor"
          d="M418.9,283.8c0,1.3-1.1,2.5-2.5,2.5h-47.2c-1.4,0-2.7-1.2-2.7-2.7v-66.9c0-1.4,1.2-2.7,2.7-2.7h46.7
          c1.3,0,2.5,1.1,2.5,2.5c0,1.3-1.1,2.5-2.5,2.5h-44v18c-0.1,0.3-0.1,0.7-0.1,1v34.1c0,0.4,0,0.7,0.1,1v8.2h44.5
          C417.8,281.3,418.9,282.4,418.9,283.8z M413.7,249.3c0.4,1.6-0.9,3-2.4,3h-27.9c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h27.8
          C412.3,247.3,413.4,248.1,413.7,249.3z"
        />
        <path
          fill="currentColor"
          d="M495.1,254.1l-13,29.2c-0.8,1.8-2,2.9-3.9,2.9h-0.3c-2,0-3.1-1.1-3.9-2.9l-28.7-64.5
          c-0.3-0.7-0.6-1.4-0.6-2.1c0-2,1.5-3.5,3.6-3.5c2.1,0,2.9,1.3,3.6,2.8l26.1,61.5l11.2-26.1c0.5-1.2,1.7-2,3-2
          C494.5,249.6,496,252,495.1,254.1z M511.3,216.8c0,0.7-0.1,1.1-0.4,1.8l-7.5,16.8c-0.5,1.2-1.7,1.9-3,1.9h-0.3
          c-2.3,0-3.9-2.4-3-4.5l7.3-17c0.6-1.4,1.6-2.6,3.5-2.5C509.8,213.3,511.3,214.9,511.3,216.8z"
        />
        <path
          fill="currentColor"
          d="M544.9,262.3c0.7,1.8-0.7,3.7-2.6,3.7c-1.1,0-2.1-0.7-2.5-1.7c-1.8-4.6-2.8-9.7-2.8-15.1v-0.2
          c0-19.5,14.1-37.4,35.7-37.4c10.8,0,18,3,24.7,8.1c0.6,0.5,1,1.2,1,2.2c0,1.4-1.2,2.7-2.7,2.7c-0.4,0-1-0.2-1.7-0.7
          c-5.6-4.3-11.7-7.3-21.7-7.3c-17.6,0-29.8,14.9-29.8,32.3v0.2C542.6,253.7,543.4,258.3,544.9,262.3z M601.7,250.2v23.6
          c0,1-0.7,2.2-1.9,3.1c-6.8,5.3-15.8,9.3-26.5,9.3c-8.8,0-16.2-2.5-22-6.7c-2-1.4-0.9-4.6,1.5-4.6c0.6,0,1.1,0.2,1.5,0.5
          c5,3.8,11.4,6,19.1,6c9.2,0,17.8-3.8,23-8.3v-20.8h-22.5c-1.5,0-2.8-1.5-2.4-3c0.3-1.2,1.4-1.9,2.6-1.9h24.8
          C600.5,247.5,601.7,248.8,601.7,250.2z"
        />
        <path
          fill="currentColor"
          d="M693.5,283.8c0,1.3-1.1,2.5-2.6,2.5c-1.2,0-2.2-0.9-2.7-2.1l-8.5-18.6h-7.8c-0.9,0-1.7-0.5-2.1-1.3
          c0,0,0,0,0,0c-0.8-1.6,0.3-3.5,2.1-3.5h5.6l-19-41.8l-19.1,41.8h17.2c0.9,0,1.8,0.6,2.2,1.4c0,0,0,0,0,0c0.7,1.6-0.4,3.4-2.2,3.4
          h-19.5l-8.5,18.7c-0.5,1.2-1.3,2-2.6,2c-1.3,0-2.5-1-2.5-2.3c0-0.4,0.1-1,0.4-1.7l30.9-66.9c0.7-1.5,1.8-2.6,3.6-2.6h0.2
          c1.9,0,2.9,1,3.6,2.6l30.8,66.7C693.4,282.8,693.5,283.3,693.5,283.8z"
        />
        <path
          fill="currentColor"
          d="M772.3,235.7v0.2c0,15.2-13.1,23-28.2,23h-19.5v24.7c0,1.7-1.6,3-3.3,2.6c-1.2-0.3-2.1-1.5-2.1-2.8v-26.6
          c0-1.5,1.2-2.7,2.7-2.7h17.3c0.4,0,0.9-0.1,1.2-0.2h3.9c13.4,0,22.5-7,22.5-17.8V236c0-11.5-8.9-17.4-21.9-17.4h-20.4v23.9
          c0,1.5-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7v-26.1c0-1.5,1.2-2.7,2.7-2.7h23.5C761.3,213.6,772.3,221.7,772.3,235.7z"
        />
      </g>
    </svg>
  );
};

export default RevGapLogo;
