import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
import InputMask from "react-input-mask";

const InsuranceDemoForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    forwardingPhoneNumber: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    make: "",
    model: "",
    year: "",
    yearOptions: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const yearOptions = generateYearOptions();
    setFormData(prevFormData => ({
      ...prevFormData,
      yearOptions,
    }));
  }, []);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 20; i--) {
      years.push(i);
    }
    return years;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const sendPhoneCall = async () => {
    setIsSubmitting(true);
    // Adjust formData before sending
    const adjustedFormData = {
      ...formData,
      forwardingPhoneNumber: formData.forwardingPhoneNumber ? formData.forwardingPhoneNumber : null, // Set to null if blank
    };
    console.log("Sending API request with data:", adjustedFormData);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/request-demo-ins`, adjustedFormData, {
        headers: { "Content-Type": "application/json" },
      });
      console.log("Received API response:", response.data);

      // Adjusted check for a successful response based on the 'message' key
      if (response.status === 200 && response.data && response.data.message === "Data prepared successfully") {
        toast.success("Phone call sent successfully!");
        console.log("Call initiated successfully with response:", response.data);
      } else {
        // Handle any response codes other than 200 or unexpected message as unexpected
        toast.error("Received unexpected response from the server.");
        console.error("Unexpected response:", response.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Function to handle errors (to avoid code repetition)
  function handleError(error) {
    if (error.response) {
      console.error("Error during API request:", error.response.data);
      toast.error(`Error: ${error.response.data.error || "Failed to dispatch phone call"}`);
    } else if (error.request) {
      console.error("Error: The request was made but no response was received", error.request);
      toast.error("Error: No response from server");
    } else {
      console.error('Error', error.message);
      toast.error("Error: " + error.message);
    }
  }
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Destructure required fields from formData
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      forwardingPhoneNumber,
      street,
      city,
      state,
      zip,
      make,
      model,
      year
    } = formData;

    // Validation of Required Fields
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !street ||
      !city ||
      !state ||
      !zip ||
      !make ||
      !model ||
      !year
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // **New Validation: Ensure phone numbers are in E.164 format (+1XXXXXXXXXX)**
    const e164Regex = /^\+1\d{10}$/;

    if (!e164Regex.test(phoneNumber)) {
      toast.error("Phone number must be in the format +1XXXXXXXXXX.");
      return;
    }

    if (forwardingPhoneNumber && !e164Regex.test(forwardingPhoneNumber)) {
      toast.error("Forwarding phone number must be in the format +1XXXXXXXXXX.");
      return;
    }

    // If all validations pass, proceed to send the API request
    sendPhoneCall();
  };

    // Adjusted Styles
    const inputStyle = "mt-1 block w-11/12 mx-auto border-gray-300 px-4 py-3 rounded-lg focus:border-blue-500 focus:outline-none text-white bg-gray-700";
    const labelStyle = "text-gray-800 text-sm font-semibold mb-1";
    const dropdownStyle = "mt-1 block w-full border-2 border-gray-300 shadow-sm px-4 py-3 rounded-lg focus:border-blue-500 focus:outline-none bg-white";
    const buttonStyle = "mt-4 px-6 py-2 font-medium tracking-wide text-white capitalize transition duration-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 shadow-md transform transition duration-200 flex justify-center items-center cursor-pointer";
    const buttonBackground = "bg-blue-600 hover:bg-blue-700"; // Ensure there's a change in the hover state

    return (
      <div className="flex flex-col justify-center items-center min-h-screen px-4">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 px-8 py-5 bg-white rounded-lg shadow-md z-20 w-full max-w-md mx-auto">
          <ToastContainer />
    <div className="block">
      {/* Utilize the styled variables for each input */}
      <label className={labelStyle}>
        First Name:
        <input 
          type="text" 
          name="firstName" 
          value={formData.firstName} 
          onChange={handleInputChange} 
          placeholder="" 
          className={inputStyle} 
        />
      </label>
      <label className={labelStyle}>
        Last Name:
        <input 
          type="text" 
          name="lastName" 
          value={formData.lastName} 
          onChange={handleInputChange} 
          placeholder="" 
          className={inputStyle} 
        />
      </label>
      <label className={labelStyle}>
        Email:
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleInputChange} 
          placeholder="" 
          className={inputStyle} 
        />
      </label>
      <label className="block">
        <span className={labelStyle}>Phone Number</span>
        <InputMask
          mask="+99999999999"
          maskChar={null} // Makes additional characters optional
          value={formData.phoneNumber}
          onChange={handleInputChange}
          placeholder="+1 234 567 8900"
          className={inputStyle}
          name="phoneNumber"
          required
        />
      </label>
      <label className={labelStyle}>
        Street:
        <input 
          type="text" 
          name="street" 
          value={formData.street} 
          onChange={handleInputChange} 
          placeholder="" 
          className={inputStyle} 
        />
      </label>
      <label className={labelStyle}>
          City:
          <input 
            type="text" 
            name="city" 
            value={formData.city} 
            onChange={handleInputChange} 
            placeholder="" 
            className={inputStyle} 
          />
        </label>

        <label className={labelStyle}>
          State:
          <input 
            type="text" 
            name="state" 
            value={formData.state} 
            onChange={handleInputChange} 
            placeholder="" 
            className={inputStyle} 
          />
        </label>

        <label className={labelStyle}>
          Zip Code:
          <input 
            type="text" 
            name="zip" 
            value={formData.zip} 
            onChange={handleInputChange} 
            placeholder="" 
            className={inputStyle} 
          />
        </label>

        <label className={labelStyle}>
    Vehicle Year:
    <select 
      name="year" 
      value={formData.year} 
      onChange={handleInputChange} 
      className={dropdownStyle} 
    > 
      <option value="">Select Year</option> 
      {formData.yearOptions.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  </label>

        <label className={labelStyle}>
          Vehicle Make:
          <input 
            type="text" 
            name="make" 
            value={formData.make} 
            onChange={handleInputChange} 
            placeholder="" 
            className={inputStyle} 
          />
        </label>

        <label className={labelStyle}>
          Vehicle Model:
          <input 
            type="text" 
            name="model" 
            value={formData.model} 
            onChange={handleInputChange} 
            placeholder="" 
            className={inputStyle} 
          />
        </label>

        <label className="block">
          <span className={labelStyle}>Transfer Number</span>
          <InputMask
            mask="+99999999999"
            maskChar={null} // Makes additional characters optional
            value={formData.forwardingPhoneNumber}
            onChange={handleInputChange}
            placeholder="+1 234 567 8900"
            className={inputStyle}
            name="forwardingPhoneNumber"
            optional
          />
        </label>
      </div>
      <button
        type="submit"
        style={{ backgroundColor: isSubmitting ? '#93c5fd' : '#3b82f6' }}
        className={`mt-4 ${buttonStyle} ${buttonBackground} shadow-md transform transition duration-200 flex justify-center items-center cursor-pointer`}
        disabled={isSubmitting}
      >
        {isSubmitting && (
          <Oval
            height={16}
            width={16}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fefefe"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        )}
        <span className="ml-2">Request Demo</span>
      </button>
    </form>
  </div>
);

};

export default InsuranceDemoForm;